import React, { useState, useMemo, useCallback, useEffect } from "react";
import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { handleLocation } from "../../features/formSlice";
import Loader from "../loader/Loader";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";

const GOOGLE_MAPS_API_KEY =
  process.env.REACT_APP_GOOGLE_MAP_API ||
  "AIzaSyAD6MEdvn4x-tPDeHWYs22mM1hGUhJq5-Q";
const libraries = ["places"];

const Places = () => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: GOOGLE_MAPS_API_KEY,
    libraries,
  });

  if (!isLoaded) return <Loader />;
  return <Map />;
};

const containerStyle = {
  width: "100%",
  height: "400px",
};

const Map = () => {
  const defaultCenter = useMemo(
    () => ({
      lat: 24.7136,
      lng: 46.6753,
    }),
    []
  );
  const [selected, setSelected] = useState(null);
  const [currentLocation, setCurrentLocation] = useState(null);
  const [mapCenter, setMapCenter] = useState(defaultCenter);
  const dispatch = useDispatch();

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          const currentLocation = { lat: latitude, lng: longitude };

          setCurrentLocation(currentLocation);
          setSelected(currentLocation);
          setMapCenter(currentLocation);
        },
        (error) => {
          const currentLocation = {
            lat: 24.7136,
            lng: 46.6753,
          };
          setCurrentLocation(currentLocation);
          setSelected(currentLocation);
          setMapCenter(currentLocation);
          console.error("Error getting current location:", error);
          dispatch(handleLocation(""));
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
      dispatch(handleLocation(""));
    }
  }, [dispatch]);

  const handleMapClick = useCallback((event) => {
    if (event.latLng) {
      const { lat, lng } = event.latLng.toJSON();
      setSelected({ lat, lng });
      setMapCenter({ lat, lng });
    }
  }, []);

  const handleMarkerDrag = useCallback((event) => {
    if (event.latLng) {
      const { lat, lng } = event.latLng.toJSON();
      setSelected({ lat, lng });
      setMapCenter({ lat, lng });
    }
  }, []);

  const handleSelect = async (address) => {
    const results = await getGeocode({ address });
    const { lat, lng } = await getLatLng(results[0]);
    setSelected({ lat, lng });
    setMapCenter({ lat, lng });
  };

  return (
    <Container>
      <PlacesAutocomplete
        handleSelect={handleSelect}
        currentLocation={currentLocation}
        selected={selected}
      />
      <GoogleMap
        zoom={10}
        mapContainerStyle={containerStyle}
        center={mapCenter}
        onClick={handleMapClick}
      >
        {currentLocation && (
          <Marker
            position={currentLocation}
            icon={{
              url: "https://maps.google.com/mapfiles/ms/icons/blue-dot.png",
            }}
          />
        )}
        {selected && (
          <Marker position={selected} draggable onDragEnd={handleMarkerDrag} />
        )}
      </GoogleMap>
    </Container>
  );
};

const PlacesAutocomplete = ({ handleSelect, currentLocation, selected }) => {
  const {
    ready,
    value,
    setValue,
    suggestions: { status, data },
    clearSuggestions,
  } = usePlacesAutocomplete();
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (currentLocation || selected) {
      const location = selected ? selected : currentLocation;
      // Check if location exists
      if (location) {
        const { lat, lng } = location;
        fetch(
          `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${GOOGLE_MAPS_API_KEY}`
        )
          .then((response) => response.json())
          .then((data) => {
            if (data.results && data.results.length > 0) {
              setValue(data.results[0].formatted_address, false);
              dispatch(
                handleLocation(`http://maps.google.com?q=${lat}+${lng}`)
              );
            }
          })
          .catch((error) => {
            dispatch(handleLocation(""));
          });
      }
    }
  }, [setValue, selected, currentLocation, dispatch]);

  const handleAutocompleteSelect = async (address) => {
    setValue(address, false);
    clearSuggestions();
    handleSelect(address);
  };

  return (
    <Autocomplete
      sx={{
        width: "100%",
        mb: 2,
        borderRadius: "15px",
        direction: i18n.language === "en" ? "ltr" : "rtl",
        "& label": {
          transformOrigin:
            i18n.language === "en" ? "left !important" : "right !important",
          right: i18n.language === "ar" ? "1.75rem !important" : "",
          left: i18n.language === "ar" ? "35.75rem !important" : "",
          fontSize: "small",
          color: "#807D7B",
          fontWeight: 400,
        },
        "& legend": { textAlign: i18n.language === "en" ? "left" : "right" },
      }}
      options={data.map((suggestion) => suggestion.description)}
      getOptionLabel={(option) => option}
      disabled={!ready}
      value={value}
      onChange={(event, newValue) => {
        if (newValue) {
          handleAutocompleteSelect(newValue);
        } else {
          dispatch(handleLocation(""));
        }
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={t("Search an address")}
          variant="outlined"
          className="combobox-input"
        />
      )}
      onInputChange={(event, newInputValue) => setValue(newInputValue)}
      className="autocomplete-container"
    />
  );
};

export default Places;
